









































import { Component, Vue } from 'vue-property-decorator';
@Component
export default class P0910 extends Vue {}
